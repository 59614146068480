import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

import { Container, Flex, Wrapper } from "../style/Grid";
import { Heading } from "../style/Heading";
import { OurFocusItem } from "../style/OurFocus/OurFocusItem";
import {
  Consulting,
  CyberSec,
  Lectures,
  M2m,
  SmartDevice,
} from "../style/Products";
import { parseContent } from "../utils/lang";

const Icons = {
  Consulting: <Consulting />,
  CyberSec: <CyberSec style={{ left: "57%" }} />,
  Lectures: <Lectures />,
  M2m: <M2m />,
  SmartDevice: <SmartDevice />,
};

const OurFocus = ({ translations, lang }) => {
  const query = useStaticQuery(graphql`
    query ProductQuery {
      products: allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "product" } } }
      ) {
        nodes {
          frontmatter {
            smallItem {
              name
              iconComponent
            }
            smallItem_cz {
              name
              iconComponent
            }
            slug
            slug_cz
            order
          }
        }
      }
      overView: markdownRemark(
        frontmatter: { templateKey: { eq: "productsOverview" } }
      ) {
        frontmatter {
          smallProducts {
            name
            iconComponent
            order
            link
          }
          smallProducts_cz {
            name
            iconComponent
            order
            link
          }
        }
      }
    }
  `);

  const bigProducts = query.products.nodes.map(item =>
    parseContent(item, lang)
  );
  const smallProducts = parseContent(query.overView, lang).frontmatter
    .smallProducts;

  return (
    <Wrapper bg="lightTitaniumGrey30" py={[4, null, null, 5]}>
      <Container>
        <Heading as="h2" fontSize="h2">
          {translations.focusTitle}
        </Heading>

        <Flex flexWrap="wrap" justifyContent="center">
          {bigProducts
            .sort((a, b) => a.frontmatter.order - b.frontmatter.order)
            .map((item, index) => {
              return (
                <OurFocusItem
                  as={Link}
                  key={index}
                  to={item.frontmatter.slug}
                  variant="dark"
                >
                  <div>{Icons[item.frontmatter.smallItem.iconComponent]}</div>
                  <Heading as="h3" fontSize={["default", "h4"]} mt={3}>
                    {item.frontmatter.smallItem.name}
                  </Heading>
                </OurFocusItem>
              );
            })}

          {smallProducts
            .sort((a, b) => a.order - b.order)
            .map((item, index) => (
              <OurFocusItem as={Link} key={index} to={item.link} variant="dark">
                <div>{Icons[item.iconComponent]}</div>
                <Heading as="h3" fontSize={["default", "h4"]} mt={3}>
                  {item.name}
                </Heading>
              </OurFocusItem>
            ))}
        </Flex>
      </Container>
    </Wrapper>
  );
};

export default OurFocus;

// import 'swiper/swiper-bundle.min.css';

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay, EffectFade } from "swiper";
import PropTypes from "prop-types";

import { Box, Container, Wrapper } from "../style/Grid";
import { SwiperCarousel, SwiperWrapper } from "../style/SwiperCarousel";
import { Heading } from "../style/Heading";
import { Button } from "../style/Button";
import { Link } from "gatsby";
import HeaderInner from "./HeaderInner";

import "swiper/swiper-bundle.min.css";

SwiperCore.use([Pagination, Autoplay, EffectFade]);

const Carousel = props => {
  if (!props.items) return;

  return (
    <SwiperWrapper>
      <Wrapper position="relative" zIndex={1000}>
        <HeaderInner {...props} />
      </Wrapper>

      <div className="bg" />
      <Container>
        <SwiperCarousel>
          <Swiper
            autoplay={{ delay: 6000 }}
            slidesPerView={1}
            pagination={{ clickable: true }}
            effect="fade"
            fadeEffect={{ crossFade: true }}
          >
            {props.items.map((item, index) => (
              <SwiperSlide key={index}>
                <Box position="absolute" bottom={[80, 150]} left={0}>
                  <Heading
                    as="h2"
                    fontSize={["h2", "h1"]}
                    fontWeight="medium"
                    color="white"
                    mb={4}
                    dangerouslySetInnerHTML={{ __html: item.text }}
                  />
                  <Button variant="cyber" as={Link} to={item.link}>
                    {props.translations.more}
                  </Button>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </SwiperCarousel>
      </Container>
    </SwiperWrapper>
  );
};

Carousel.propTypes = {
  items: PropTypes.array.isRequired,
};

export default Carousel;

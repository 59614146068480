import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

import { Box } from "../Grid";

const SwiperCarousel = styled(Box)`
  position: relative;
  z-index: 500;
  .swiper-container {
    min-height: 530px;
  }
  .swiper-slide {
    min-height: 490px;
    padding-bottom: 70px;
    h2 strong {
      display: block;
      color: ${themeGet("colors.cyberGreen")};
    }
  }
  .swiper-pagination-bullet {
    opacity: 1;
    width: 10px;
    height: 10px;
    background-color: ${themeGet("colors.white")};
  }
  .swiper-pagination-bullet-active {
    background-color: transparent;
    box-shadow: 0 0 0 2px ${themeGet("colors.cyberGreen")};
  }
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 25px;
  }

  //.swiper-slide {
  //  opacity: 0 !important;
  //}
  //.swiper-slide-active {
  //  opacity: 1 !important;
  //}
`;

export { SwiperCarousel };

import React, { Fragment, useState } from "react";
import { Link } from "gatsby";
import parse from "html-react-parser";

import { Box, Container, Flex, Wrapper } from "../style/Grid";
import { Heading } from "../style/Heading";
import { Button } from "../style/Button";
import { Text } from "../style/Text";


export default ({ text, translations, links }) => {
  const [enabled, setEnabled] = useState(true);

  return (
    <Fragment>
      <Wrapper bg="lightTitaniumGrey">
        <Container>
          <Flex flexWrap="wrap">
            <Box
              width={[1, null, null, 1]}
              pr={[3]}
              py={[4, null, null, 5]}
            >
              <Heading as="h3" fontSize="h3" mb={4}>
                {translations.about}
              </Heading>

              <Text mb={4} pr={[null, null, null, 5]} as={"div"}>
                {parse(text)}
              </Text>

              <Button as={Link} to={links.about}>
                {translations.moreAboutUs}
              </Button>
            </Box>

          </Flex>
        </Container>
      </Wrapper>

    </Fragment>
  );
};

import React, { Fragment } from "react";
import { graphql } from "gatsby";

import OurFocus from "../components/OurFocus";
import AboutBlock from "../components/AboutBlock";
import Carousel from "../components/Carousel";
import Meta from "../components/Meta";
import { parseContent } from "../utils/lang";

export default ({ data, pageContext, mobileMenuOpen, toggleMobileMenu }) => {
  const { frontmatter, html } = parseContent(
    data.markdownRemark,
    pageContext.lang
  );

  return (
    <Fragment>
      <Meta
        title={frontmatter.meta.title}
        description={frontmatter.meta.description}
      />

      <Carousel
        mobileMenuOpen={mobileMenuOpen}
        toggleMobileMenu={toggleMobileMenu}
        items={frontmatter.carousel}
        translations={pageContext.translations}
        links={pageContext.links}
        slug={pageContext.currentSlug}
        lang={pageContext.lang}
      />

      <OurFocus
        lang={pageContext.lang}
        translations={pageContext.translations}
      />

      <AboutBlock
        text={html}
        translations={pageContext.translations}
        links={pageContext.links}
      />
    </Fragment>
  );
};
export const pageQuery = graphql`
  query DEFAULT_QUERY($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        slug_cz

        meta {
          title
          description
        }
        meta_cz {
          title
          description
        }

        carousel {
          text
          link
        }
        carousel_cz {
          text
          link
        }
      }
    }
  }
`;
